import { useState, useEffect, useRef, useCallback } from "react";
import useProducts from "../../hooks/useProducts";
import ProductCard from "./components/productCardSmall";
import SearchBar from "./components/searchBarSmall";
import { Container, Grid, Switch } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import "./products.css";
import { Link } from "react-router-dom";

const Products = () => {
  const [searching, setSearching] = useState({
    page: 1,
    limit: 24,
    start: "",
    end: "",
    sortBy: "Number",
    orderBy: 1,
    includeArchived: false,
    searchQuery: "",
    categorie: "Tous",
    decor: "Tous"
  });

  const [searchQuery, setSearchQuery] = useState("");

  const {
    isLoading,
    isError,
    error,
    products,
    hasNextPage,
    totalProducts
  } = useProducts({ searchQuery, searching });

  const intObserver = useRef();

  const lastProductRef = useCallback(
    (product) => {
      if (isLoading) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((products) => {
        if (products[0].isIntersecting && hasNextPage) {
          console.log("We are near the last product!!");
          setSearching({
            ...searching,
            page: searching.page + 1
          });
        }
      });

      if (product) intObserver.current.observe(product);
    },
    [isLoading, hasNextPage]
  );

  if (isError) return <p>Error : {error.message}</p>;

  const content = products.map((product, i) => {
    if (products.length === i + 1) {
      return (
        <ProductCard ref={lastProductRef} key={product._id} product={product} />
      );
    }
    return <ProductCard key={product._id} product={product} />;
  });

  return (
    <div className="main-content">
      <Container maxWidth="lg">
        <div className="header">
          <div className="text">
            <div className="title">Produits</div>
            <div className="sub-title">Gérer et consulter vos produits.</div>
          </div>
          <Link to="/Produit/Ajouter" title="Ajouter un nouveau produit">
            <AddBoxIcon className="add-icon" />
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <SearchBar
              searching={searching}
              setSearching={setSearching}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <div style={{ marginLeft: "5px" }}>
              {totalProducts && totalProducts} produits
            </div>
          </div>
          <div
          style={{ display: "flex", alignItems: "center" }}
          >
            <div>Les produits archivés</div>
            <Switch
              checked={searching.includeArchived}
              onChange={() =>
                setSearching({
                  ...searching,
                  includeArchived: !searching.includeArchived
                })
              }
            />
          </div>
        </div>

        <div style={{ padding: "5px" }} id="searchResult">
          <Grid container spacing={3}>
            {content}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Products;
