import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GlobalContextProvider} from './context/GlobalContextProvider'
import { SnackbarProvider } from './context/snackbarContext';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GlobalContextProvider>
      <SnackbarProvider>
      <App />
      </SnackbarProvider>
      
    </GlobalContextProvider>
);
