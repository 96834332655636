import { createContext, useReducer } from 'react'

export const ProductContext = createContext()

export const productsReducer = (productState, action) => {
    switch(action.type){
        case 'SET_PRODUCTS':
            return {
                products: action.payload.sort(function(a, b) {
                    return (a.Name > b.Name) - (a.Name < b.Name);
                    })
            }
        case 'SET_PRODUCT':
                //console.log("Load un Produit")
                //console.log(action.payload)
            return {
                
                
                //products: state.products.filter((p) => p.id !== action.payload._id)
            }   
        case 'CREATE_PRODUCT':
            return {
                products:[action.payload, ...productState.products]
            }
            case 'DELETE_PRODUCT':
                return {
                    products: productState.products.filter((p) => p._id !== action.payload._id)
                }
                
        default:
            return productState
    }
}
