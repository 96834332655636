import { useState, useEffect } from "react";
import { getProductsPage } from "../api/axios";
import { useGlobalContext } from "./useGlobalContext";

const useProducts = ({ searching, searchQuery }) => {
  const { categories, tags } = useGlobalContext();

  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState()
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({});
  const [hasNextPage, setHasNextPage] = useState(false);

  const [categoryPath, setCategoryPath] = useState("");

  const createPath = () => {
    if (searching.categorie == "Tous") {
      setCategoryPath("Tous");
    } else if (!searching.categorie.parentId) {
      setCategoryPath(searching.categorie.Name);
    } else {
      const subCategory = categories.filter(
        (category) => category._id === searching.categorie.parentId
      );
      if (subCategory) {
        const subSubCategory = categories.filter(
          (category) => category._id === subCategory[0].parentId
        );
        if (subSubCategory.length >> 0) {
          setCategoryPath(
            subSubCategory[0].Name +
              " / " +
              subCategory[0].Name +
              " / " +
              searching.categorie.Name
          );
        } else {
          setCategoryPath(
            subCategory[0].Name + " / " + searching.categorie.Name
          );
        }
      }
    }
    if (searching.decor && searching.decor != "Tous") {
      const activeTab = tags.filter((tag) => tag._id === searching.decor._id);
      if (activeTab) {
        setCategoryPath(activeTab[0].Name);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setError({});

    const controller = new AbortController();
    const { signal } = controller;

    getProductsPage(searching, searchQuery, { signal })
      .then((data) => {
        if (searching.page === 1) {
            // Reset the products array when the page changes to 1
            setProducts([...data.products]);
            setTotalProducts(data.total)
            
          }
        else{
            setProducts((prev) => [...prev, ...data.products]);
            setTotalProducts(data.total)
            
        }
        
        setHasNextPage(Boolean(data.products.length));
        createPath();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (signal.aborted) return;
        setIsError(true);
        setError({ message: e.message });
      });

    return () => controller.abort();
  }, [searching]);

  return { isLoading, isError, error, products, setProducts, hasNextPage, categoryPath, totalProducts };
};

export default useProducts;
